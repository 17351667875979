<template>
  <div class="post-list">
    <PostItem v-for="post in unitPosts" :key="post.postId" :post="post" />
  </div>
</template>

<script>
import PostItem from '../post-list-item/PostItem'

export default {
  name: 'PostList',
  components: {
    PostItem
  },
  props: {
    unitPosts: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
