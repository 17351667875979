import { render, staticRenderFns } from "./PostItem.vue?vue&type=template&id=6220d996&scoped=true&"
import script from "./PostItem.vue?vue&type=script&lang=js&"
export * from "./PostItem.vue?vue&type=script&lang=js&"
import style0 from "./PostItem.vue?vue&type=style&index=0&id=6220d996&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6220d996",
  null
  
)

export default component.exports