<template>
  <router-link :to="{
      name: $options.POST_VIEW_ROUTE_NAME,
      params: {postID: post.postId}
    }"
  >
    <div class="post-list__item post">
      <div v-if="imageSource" class="post__img-wrapper">
        <img :src="imageSource" alt="image">
      </div>
      <div
          class="post__content"
          :class="{ 'post__content post__content--no-image': !post.photo }"
      >
        <h3 class="heading post__title">
          {{ post.title }}
        </h3>
        <p class="post__description">
          {{ post.description }}
        </p>
        <div class="d-flex justify-between post__footer">
          <time>
            {{ post.createdAt }}
          </time>
          <span>
            {{ $t(`page_unit.post_status.${ post.isPublished ? 'published' : 'unpublished' }`)  }}
          </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import {UPLOADS_FOLDER_URL} from '../../../../../../shared/constants/backend'
import {POST_VIEW_ROUTE_NAME} from '../../../../../../shared/constants/app-routes-names'

export default {
  name: 'PostItem',
  POST_VIEW_ROUTE_NAME,
  props: {
    post: {
      type: Object,
      required: true,
    }
  },
  computed: {
    imageSource() {
      if (!this.post.photo) {
        return null
      }
      return `${UPLOADS_FOLDER_URL}/${this.post.photo}`
    }
  }
}
</script>

<style scoped lang="scss">
  .post {
    color: #606266;
    display: flex;
    border-bottom: 1px solid #E4E7ED;
    margin-top: 0.9em;
    padding-bottom: 0.9em;
    &__img-wrapper {
      max-width: 30%;
      width: 100%;
      position: relative;
      padding-bottom: 8.5em;
      border-radius: 0.9em;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
    &__content {
      padding: 0.2em 0 0 1.2em;
      width: 100%;
      display: grid;
      grid-template-rows: 1.5em auto 1.2em;
      &--no-image {
        padding: 0;
      }
    }
    &__footer {
      color: #909399;
      font-style: italic;
    }
  }
</style>
