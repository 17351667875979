<template>
  <div class="empty-state-container">
    <div class="empty-state-img-wrapper">
      <img :src="image" alt="">
    </div>
      <div v-if="subtitle || description" class="empty-state-text">
        <h4 v-if="subtitle" class="heading empty-state-subtitle">
          {{ subtitle }}
        </h4>
        <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
          <p v-if="description" class="empty-state-description">
            {{ description }}
          </p>
        </can-i>
      </div>
  </div>
</template>

<script>

import CanI from '../../../auth/can-i/CanI'
import { ROLE_UNIT_ADMINISTRATION } from '../../../shared/constants/app-permissions'

export default {
  name: 'VEmptyState',
  components: {CanI},
  ROLE_UNIT_ADMINISTRATION,
  props: {
    image: {
      type: String,
      required: false,
      default: () => require('../../../../assets/images/empty_state_folder.svg')
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
}
</script>

<style scoped lang="scss">
.empty-state {
  &-container {
    max-width: 320px;
    margin: 0 auto;
  }
  &-img-wrapper {
    img {
      width: 100%;
    }
  }
  &-text {
    text-align: center;
    font-size: 1em;
    margin-top: 1.6em;
  }
  &-subtitle {
    margin-bottom: 1em;
  }
  &-description {
    font-size: 0.9em;
    margin-top: 0;
    color: #606266;
  }
}
</style>
