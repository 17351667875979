import {api} from '../../../shared/services/backend-api'

const fetchUnitPosts = async (unitID) => {
  const { data } = await api.get(`units/${unitID}/posts`)
  
  return data.data
}

const fetchPost = async (postID) => {
  const { data } = await api.get(`posts/${postID}`)
  const { title, content, isPublished } = data
  
  return {
    title,
    content,
    isPublished
  }
}


export {
  fetchUnitPosts,
  fetchPost
}
