<template>
  <div v-loading="isLoading">
    <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
      <div class="unit-actions">
        <v-button
            icon="edit"
            @click="goToPostCreate"
        >
          {{ $t('page_unit.btn_create_post') }}
        </v-button>
      </div>
    </can-i>
    <v-empty-state v-if="isEmptyState"
                   :subtitle="$t('page_unit.empty_states.unit_posts.subtitle')"
                   :description="$t('page_unit.empty_states.unit_posts.description')"
    />
    <PostList v-else
              :unit-posts="unitPosts"
    />
  </div>
</template>

<script>
import VButton from '../../../components/ui/v-button/VButton'

import {
  POST_CREATE_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'

import {fetchUnitPosts} from '../../unit-page/services/unit-post-service'

import PostList from '../../unit-page/unit-content/unit-tab-blog/components/post-list/PostList'
import CanI from '../../../auth/can-i/CanI'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'
import VEmptyState from '../../../components/ui/v-empty-state/VEmptyState'

export default {
  name: 'UnitPosts',
  ROLE_UNIT_ADMINISTRATION,
  components: {VEmptyState, CanI, PostList, VButton},
  inject: ['unitID'],
  data() {
    return {
      unitPosts: [],
      isLoading: false
    }
  },
  created() {
    this.fetchPosts()
  },
  methods: {
    goToPostCreate() {
      const {unitID} = this
      this.$router.push({name: POST_CREATE_ROUTE_NAME, params: {unitID}})
    },
    fetchPosts() {
      const {unitID} = this
      this.isLoading = true
      fetchUnitPosts(unitID)
          .then((res) => {
            this.unitPosts = res
          })
          .finally(() => {
            this.isLoading = false
          })
    },
  },
  computed: {
    isEmptyState() {
      return !Boolean(this.unitPosts.length)
    }
  }
}
</script>
